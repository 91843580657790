import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { CompetitionDocument } from '@rallycry/api-suite-typescript/dist/models/CompetitionDocument'
import { DiscoverCard } from './DiscoverCard'
import { CHIPINATOR_DELIMITER } from '@/components/molecules/text/Chipinator'
import { ScheduleTypography } from '@/components/molecules/text/ScheduleTypography'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useTime } from '@/core/hooks/useTime'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { ChipProps } from '@mui/material/Chip'
import { ListingMode } from '@rallycry/api-suite-typescript'
import { RcIcon } from '@/components/atoms/RcIcon'
import { PrettifyJson } from '@/components/atoms/dev/PrettifyJson'

export const CompetitionDocumentCard = ({
  googleTag,
  resource
}: {
  googleTag?: string
  resource: CompetitionDocument
}) => {
  const { navTo } = useNavigation()
  const { getNow } = useTime()
  const {
    featCompetitionDiscoveryAvatars,
    featCompetitionDiscoveryBanners,
    imgCommunityBanner
  } = useFeatures()

  const defaultBannerImageSrc = imgCommunityBanner as string

  const now = getNow()
  const activeEvent = resource.events?.find(
    it => now.isAfter(it.startDate) && now.isBefore(it.endDate)
  )

  const hiddenChip =
    resource?.listingMode === ListingMode.NONE
      ? ({
          label: 'Hidden',
          color: 'default',
          icon: <RcIcon icon={['fal', 'eye']} size='xs' />
        } as ChipProps)
      : null
  const gameChip = resource?.game
    ? ({
        label: resource.game?.name,
        color: 'primary',
        icon: (
          <RcAvatar
            variant='rounded'
            alt={resource.game?.name}
            src={resource.game?.icon || resource.game?.image}
            sx={{ width: 18, height: 18 }}
          />
        ),
        onClick: () =>
          navTo(
            {
              root: RootRoute.Discovery,
              subRoute: DiscoveryRoute.Competitions,
              query: `tag=${resource.game?.name}`
            },
            true
          ),
        sx: { cursor: 'pointer' }
      } as ChipProps)
    : null
  const chipinatorValues =
    resource?.disambiguatingDescription?.split(CHIPINATOR_DELIMITER) || []

  // return <PrettifyJson value={resource} />
  return (
    <DiscoverCard
      avatarImage={
        featCompetitionDiscoveryAvatars
          ? resource.image || resource.game?.icon
          : undefined
      }
      headerImage={
        featCompetitionDiscoveryBanners
          ? resource.image || resource.game?.image || defaultBannerImageSrc
          : undefined
      }
      navigation={{
        root: RootRoute.Competition,
        rootId: resource.id
      }}
      googleTag={googleTag}
      key={resource.id}
      title={resource.name}
      content={
        <Stack display='flex' direction='row' spacing={1} alignItems='center'>
          <Box>
            {activeEvent ? (
              <ScheduleTypography
                startDate={activeEvent?.startDate}
                endDate={activeEvent?.endDate}
                name={activeEvent?.name}
              />
            ) : null}
          </Box>
        </Stack>
      }
      primaryChips={[hiddenChip, gameChip].filter(it => !!it)}
      secondaryChips={chipinatorValues.map(it => ({
        label: it,
        color: 'primary',
        onClick: () =>
          navTo(
            {
              root: RootRoute.Discovery,
              subRoute: DiscoveryRoute.Competitions,
              query: `tag=${it}`
            },
            true
          ),
        sx: { cursor: 'pointer' }
      }))}
    />
  )
}

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { PropsWithChildren } from 'react'
import { usePage } from '@/components/providers/site/PageProvider'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'

export const Page = ({
  children,
  unauthFullWidth
}: PropsWithChildren & { unauthFullWidth?: boolean }) => {
  const isMainLayout = useIsMainLayout()
  const fullWidthPage = unauthFullWidth && !isMainLayout
  const { confettiContainer } = usePage()

  return (
    <Box>
      <Stack
        direction='column'
        spacing={fullWidthPage ? 0 : 2}
        className={unauthFullWidth ? 'unauth-full-width' : undefined}
      >
        {children}
      </Stack>
      <Box ref={confettiContainer} />
    </Box>
  )
}

import { useEffect, useState, MutableRefObject } from 'react'

/*
 * Detects if a DOM ref is currently visible to the user.
 */
export const useOnScreen = (
  ref: MutableRefObject<any>,
  rootMargin = '0px',
  root?: MutableRefObject<any>
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const c = ref.current
    if (!c) return
    const observer = new IntersectionObserver(
      ([el]) => {
        setIntersecting(el.isIntersecting)
      },
      {
        root: root?.current,
        rootMargin
      }
    )
    if (c) {
      observer.observe(c)
    }
    return () => {
      observer.unobserve(c)
    }
  }, [ref, rootMargin, root])

  return isIntersecting
}

import MenuItem from '@mui/material/MenuItem'
import { uniq } from 'lodash-es'
import moment from 'moment-timezone'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { useTime } from '@/core/hooks/useTime'

export enum Timezone {
  Pacific = 'America/Los_Angeles',
  Mountain = 'America/Denver',
  Central = 'America/Chicago',
  Eastern = 'America/New_York'
}

export const TimezonePreference = () => {
  const { tz, setTz } = useTime()

  // always allow user's actual TZ in addition to our hardcoded American ones the ones we like
  const tzOptions = uniq([moment.tz.guess(), ...Object.values(Timezone)])

  return (
    <RcSelect
      property='tz'
      source={{ tz: tz }}
      update={async val => {
        setTz(val.tz!)
      }}
    >
      {tzOptions.map(it => (
        <MenuItem key={it} value={it}>
          {it}
        </MenuItem>
      ))}
    </RcSelect>
  )
}

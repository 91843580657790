import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { ChipProps } from '@mui/material/Chip'
import { drop } from 'lodash-es'

export const ChipGrid = ({
  chipProps,
  color,
  expandChips,
  limit = 2
}: {
  chipProps?: ChipProps[]
  color: 'primary' | 'secondary'
  expandChips?: boolean
  limit?: number
}) => {
  return (
    <Box>
      <Grid container direction='row' spacing={2}>
        {chipProps?.map((it, idx) => {
          if (expandChips || idx < limit) {
            return (
              <Grid key={idx} item>
                <Tooltip title={it.label}>
                  <Box sx={{ maxWidth: 195 }}>
                    <Chip
                      color={color}
                      {...it}
                      onClick={
                        it.onClick
                          ? async e => {
                              e.preventDefault()
                              e.stopPropagation()
                              await it.onClick?.(e)
                            }
                          : undefined
                      }
                    />
                  </Box>
                </Tooltip>
              </Grid>
            )
          } else if (idx === limit) {
            return (
              <Grid key={idx} item>
                <Tooltip
                  title={drop(chipProps, limit)
                    ?.map(it => it.label)
                    ?.join(', ')}
                >
                  <Chip label={`+${chipProps.length - limit}`} color={color} />
                </Tooltip>
              </Grid>
            )
          }
        })}
      </Grid>
    </Box>
  )
}

import LinearProgress from '@mui/material/LinearProgress'
import React, { useEffect, useRef } from 'react'
import { RcTrans } from './RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useOnScreen } from '@/core/hooks/useOnScreen'

export const InfiniteScrollV2 = ({
  showMore,
  hasMore,
  isLoading,
  onLoad
}: {
  showMore?: boolean
  hasMore: boolean
  isLoading: boolean
  onLoad: () => any
}) => {
  return showMore && !isLoading ? (
    <RcButton
      variant='text'
      fullWidth
      onClick={onLoad}
      disabled={!hasMore}
      sx={{ marginTop: 5 }}
    >
      {hasMore ? (
        <RcTrans i18nKey='shared.show-more' />
      ) : (
        <RcTrans i18nKey='shared.no-additional-results' />
      )}
    </RcButton>
  ) : hasMore && !isLoading ? (
    <Scroller onLoad={onLoad} />
  ) : isLoading ? (
    <LinearProgress />
  ) : null
}

export const Scroller = ({
  onLoad,
  rootMargin,
  root
}: {
  onLoad: () => any
  rootMargin?: string
  root?: React.MutableRefObject<any>
}) => {
  const ref = useRef(null)
  const scrolled = useOnScreen(ref, rootMargin || '350px', root)

  useEffect(() => {
    if (scrolled) {
      onLoad()
    }
  }, [scrolled, onLoad])

  return <div ref={ref} />
}

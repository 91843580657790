import { OrganizationControllerApi } from '@rallycry/api-suite-typescript/dist/apis/OrganizationControllerApi'
import {
  CompetitionDocument,
  CompetitionResource
} from '@rallycry/api-suite-typescript/dist/models'
import { useOrganization } from '../../entity/organization/useOrganization'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useController } from '@/core/hooks/useSWRApi'
import { ExpansionType, expand } from '@/core/expand'

export const useCompetitionUserView = (options?: EntityOptions) => {
  const { organization } = useOrganization()
  const { account } = useUserAccount()
  const { ctrl } = useController(OrganizationControllerApi)

  const entity = useReadEntity({
    key: 'useCompetitionUserView',
    idOrKey: organization?.id,
    paused: !account,
    suspense: false,
    persist: true,
    expand: 'ongoing{game},upcoming{game}',
    ...options,
    read: req =>
      ctrl({
        expectedStatuses: [401, 403],
        fallbackResponse: null
      }).viewUserOrganizationCompetitions(req)
  })

  const toCompetitionDocument = (entity: CompetitionResource) => {
    const game = expand(entity.game, entity._expanded, ExpansionType.Game)
    return { ...entity, game } as CompetitionDocument
  }

  const userView = entity.read.data || {}
  const current = [
    ...(userView?.ongoing || []),
    ...(userView?.upcoming || [])
  ]?.map(it => toCompetitionDocument(it))

  return { ...entity, userView, current }
}

import {
  CompetitionDocumentControllerApi,
  SearchCompetitionsRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionDocumentControllerApi'
import { useOrganization } from '../../entity/organization/useOrganization'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionDocument = (
  options: EntityOptions<SearchCompetitionsRequest>
) => {
  const { organization } = useOrganization()
  const { ctrl } = useController(CompetitionDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionDocument',
    paused: !options.request,
    persist: true,
    ...options,
    request: {
      organizationId: organization?.id,
      ...options.request,
      q: options?.request?.q || undefined
    },
    read: req => ctrl().searchCompetitions(req)
  })

  return { ...entity, competitions: entity.flat }
}
